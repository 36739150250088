<template>
  <div class="collection-exchange-container">
    <p class="collection-exchange-title">藏品兑换抽奖次数</p>
    <div class="collection-exchange-content">
      <div
        v-for="(item,index) in collectionExchangePlan"
        :key="index"
        class="collection-exchange-item row-center"
      >
        <div class="collection-exchange-item-left row-start">
          <div
            v-for="(m,n) in item.skuList.slice(0,3)"
            :key="n"
            class="col-center collection-exchange-collection-content"
          >
            <div class="collection-content-bg row-center">
              <img :src="m.image" />
            </div>
            <p>x{{m.amount}}</p>
            <p>{{m.name}}</p>
          </div>
        </div>
        <img
          class="collection-exchange-next"
          src="../../assets/icon/gashapon/collection-exchange-next.png"
        />
        <div class="collection-exchange-item-right col-center">
          <p class="exchange-item-right-title">抽奖次数</p>
          <p class="exchange-item-right-num">{{item.num}}</p>
          <img
            @click="goExchange(item)"
            class="exchange-item-right-button"
            src="../../assets/icon/gashapon/collection-exchange-button.png"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from '../../api/index-client'

export default {
  data () {
    return {
      collectionExchangePlan: [],
    }
  },
  watch: {
  },
  mounted () {
    // 获取用户藏品兑换方案兑换
    this.getPlans()
  },
  methods: {
    // 获取用户兑换方案
    getPlans () {
      api
        .get('lottery/exchange/getPlans?lotteryCode=' + this.$route.query.lotteryCode)
        .then(result => {
          if (result.data.success) {
            this.collectionExchangePlan = result.data.data
            this.$store.commit('HIDE_APPLOADING')
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../../assets/icon/toast-error.png'),
            });
          }
        })
    },
    goExchange (item) {
      localStorage.setItem('collectionExchangeItem',JSON.stringify(item))
      localStorage.removeItem('submitAssetsList')
      sessionStorage.setItem('value',1)
      this.$router.push('/collectionExchangeDetail?lotteryCode=' + this.$route.query.lotteryCode)
    },
  },
}
</script>
<style lang="less" scoped>
.collection-exchange-container {
  width: 100vw;
  min-height: 100vh;
  height: auto;
  position: absolute;
  top: 0;
  background: #fffaf4;
  .collection-exchange-title {
    width: 100%;
    text-align: center;
    font-size: 1.125rem;
    font-weight: 900;
    color: #1c1c1c;
    line-height: 1.5625rem;
    padding-top: 1.5rem;
  }
  .collection-exchange-content {
    width: 100%;
    margin-top: 60px;
    position: relative;
    .collection-exchange-item {
      width: 92vw;
      margin-left: 4vw;
      margin-bottom: 20px;
      background-image: url('../../assets/icon/gashapon/collection-exchange-item-bg.png');
      background-size: 100% auto;
      background-repeat: no-repeat;
      height: 34vw;
      .collection-exchange-item-left {
        width: 63vw;
        height: 34vw;
        .collection-exchange-collection-content {
          margin-left: 3vw;
          .collection-content-bg {
            width: 17.4vw;
            height: 17.4vw;
            background-image: url('../../assets/icon/gashapon/collection-exchange-item-image-bg.png');
            background-size: 100% auto;
            background-repeat: no-repeat;
            img {
              width: 14vw;
              height: 14vw;
            }
          }
          p {
            width: 17.4vw;
            font-size: 13px;
            font-weight: 500;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-top: 2px;
          }
        }
      }
      .collection-exchange-item-right {
        width: 23.5vw;
        height: 34vw;
        .exchange-item-right-title {
          font-size: 13px;
          font-weight: 500;
        }
        .exchange-item-right-num {
          font-size: 25px;
          margin: 5px 0 6px 0;
          font-weight: bold;
        }
        .exchange-item-right-button {
          width: 26.7vw;
          height: 12vw;
        }
        .exchange-item-right-button:active {
          opacity: 0.6;
        }
      }
    }
    .collection-exchange-next {
      width: 5vw;
      height: 5vw;
    }
  }
}
</style>